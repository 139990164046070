import React from 'react';
import {Grid, CircularProgress} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	progress: {
		margin: theme.spacing(2),
	},
	root: {
		textAlign: 'center'
	}
}));

const LoadingMessage = () => {
	const classes = useStyles();
	return <Grid container spacing={2}>
		<Grid item xs={12} className={classes.root}>
			<CircularProgress size={60} thickness={5} className={classes.progress}  color="secondary"/>
		</Grid>
	</Grid>;
};

export default LoadingMessage;
