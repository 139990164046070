import React from 'react';
import LighthouseReportBase from './LighthouseReportBase';

const LighthouseReportAccessibility	= (props) => {
	return <LighthouseReportBase
		{...props}
		reportFriendlyName={'Accessibility Summary'}
		reportName={'_summary_accessibility'}
		reportCategory={'Accessibility'}
		reportIcon={'universal-access'}/>;
};


export default LighthouseReportAccessibility;
