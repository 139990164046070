import React, {useContext, useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import UrlSearch from '../../controls/UrlSearch/UrlSearch';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {MainContext} from '../../../context/main';
import LinkIcon from '@material-ui/icons/Link';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import axios from 'axios';
import {getApiUrl} from '../../../utils/config';
import Paper from '@material-ui/core/Paper';
import ContentAudit from './ContentAudit';
import LighthouseCategory51 from '../Lighthouse/LighthouseCategory51';
import LoadingMessage from '../../controls/LoadingMessage';


const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(3, 2),
	},
	title: {
		textAlign: 'right',
		paddingTop: theme.spacing(2)
	},
	linkButton: {
		height: '100%',
		marginLeft: '10px'
	},
	searchButton: {
		height: '100%',
	},
	urlField: {
		width: '100%',
		margin: 0
	},
	contentTabs: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		marginTop: theme.spacing(4)
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return <Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}        >
		<Box p={3}>{children}</Box>
	</Typography>
}

const Content = (props) => {
	const classes = useStyles();
	const {domainId} = props.match.params;

	const {reportsUrl, urls, getUrls, isAuthenticated} = useContext(MainContext);
	const [searching, setSearching] = useState(false);
	const [selectedUrl, setSelectedUrl] = useState(null);
	const [contentTab, setContentTab] = useState(0);

	const [reportDetails, setReportDetails] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		if (urls.length > 0) {
			setSelectedUrl(urls[0]);
		}
	}, [urls]);

	useEffect(() => {

		document.title = `Content - ${domainId}`;

		if (isAuthenticated) {

			window.dataLayer.push(
				{
					'event': 'viewReport',
					'report' : 'Content',
					'client': domainId
				}
			);

			getUrls(domainId);
		}
	}, [isAuthenticated]);

	useEffect(() => {
		let file = null;
		if (contentTab === 0 && selectedUrl) {
			const {name} = selectedUrl;
			file = `/content/${name}.json`;
		}
		if ((contentTab === 1 || contentTab === 4 || contentTab === 5 || contentTab === 6) && selectedUrl) {
			const {name} = selectedUrl;
			file = `/lighthouse/${name}.json`;
		}
		if (contentTab === 7 && selectedUrl) {
			const {name} = selectedUrl;
			file = `/html/${name}.html`;
		}
		if (file) {
			axios.get(getApiUrl(`${reportsUrl}?domain=${domainId}&file=${file}`))
				.then(response => {
					console.log(response);
					const {data} = response;
					setReportDetails(data);
					setLoading(false);
				})
				.catch(error => {
					setError(error);
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, [contentTab, selectedUrl]);

	const onToggleSearch = () => setSearching(!searching);

	const onAnyClick = () => {
		if (searching) {
			setSearching(false);
		}
	};

	const onSelectUrl = (url) => {
		setSelectedUrl(url);
	};

	const handleChange = (event, newValue) => {
		setLoading(true);
		setContentTab(newValue);
	};

	const createDetailReport = (reportCategory) => {
		if (reportDetails !== null) {
			const {lhr} = reportDetails;
			if (!lhr) {
				return;
			}
			const category = lhr.categories[reportCategory.toLowerCase()];
			category.audits = category.auditRefs.map(ref => lhr.audits[ref.id]);
			return <LighthouseCategory51
				selectedAuditId={'something'}
				url={selectedUrl}
				key={category.id}
				category={category}
				onToggleAudit={() => {
					console.log('Lighthouse Report Loaded')
				}}/>;
		}
		return null;
	};

	let domain = null;

	if (selectedUrl) {
		domain = selectedUrl.url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0];
	}

	return <Grid container>
		<Grid item xs={1}>
			<Button color="primary" aria-label="Find a Page" className={classes.searchButton}
					onClick={onToggleSearch}>
				<SearchIcon/>
			</Button>
			<UrlSearch
				onToggleSearch={onToggleSearch}
				cancel={onAnyClick}
				open={searching}
				urls={urls ? urls : []}
				onSelectUrl={onSelectUrl}/>
		</Grid>
		<Grid item xs={10}>
			<Tooltip title={selectedUrl ? selectedUrl.name : 'no page selected'} interactive>
				<TextField
					value={selectedUrl ? selectedUrl.url : 'no page selected'}
					disabled={true}
					className={classes.urlField}
					margin="normal"
					variant="outlined"/>
			</Tooltip>
		</Grid>
		<Grid item xs={1}>
			{selectedUrl ?
				<Button href={selectedUrl.url} target="_blank" rel="noopener noreferer"
						className={classes.linkButton}>
					<LinkIcon/>
				</Button> : null}
		</Grid>
		<Grid item xs={12}>
			<div className={classes.contentTabs}>
				<AppBar position="static" color='default'>
					<Tabs value={contentTab} onChange={handleChange}>
						<Tab label="Content"/>
						<Tab label="Accessibility"/>
						<Tab label="Advanced Accessibility"/>
						<Tab label="Broken Links"/>
						<Tab label="PWA"/>
						<Tab label="SEO"/>
						<Tab label="Performance"/>
						<Tab label="HTML"/>
					</Tabs>
				</AppBar>
				{reportDetails && !loading ? <div>
					<TabPanel value={contentTab} index={0}>
						<Paper>
							{contentTab === 0 ? <ContentAudit classes={classes} reportDetails={reportDetails}/> : null}
						</Paper>
					</TabPanel>
					<TabPanel value={contentTab} index={1}>
						{contentTab === 1 ? createDetailReport('Accessibility') : null}
					</TabPanel>
					<TabPanel value={contentTab} index={2}>
						{contentTab === 2 ? <h3>Advanced Accessibility</h3> : null}
					</TabPanel>
					<TabPanel value={contentTab} index={3}>
						{contentTab === 3 ? <h3>Broken Links</h3> : null}
					</TabPanel>
					<TabPanel value={contentTab} index={4}>
						{contentTab === 4 ? createDetailReport('PWA') : null}
					</TabPanel>
					<TabPanel value={contentTab} index={5}>
						{contentTab === 5 ? createDetailReport('SEO') : null}
					</TabPanel>
					<TabPanel value={contentTab} index={6}>
						{contentTab === 6 ? createDetailReport('Performance') : null}
					</TabPanel>
					<TabPanel value={contentTab} index={7}>
						{contentTab === 7 ? <div>
							<iframe title={selectedUrl.url} style={{width: '100%', height: '700px'}}
									srcdoc={reportDetails.replace('<head>', '<head><base href="https://' + domain + '" />')}/>
						</div> : null}
					</TabPanel>
				</div> : <LoadingMessage/>}
			</div>
		</Grid>
	</Grid>
};

export default Content;
