import React from 'react';
import LighthouseReportBase from '../Lighthouse/LighthouseReportBase';


const LighthousePerformance = (props) => {
	return <LighthouseReportBase
		{...props}
		reportName={'_summary_performance'}
		reportCategory={'Performance'}
		reportIcon={'browser'}/>;
};

export default LighthousePerformance;
