import Validator from 'validator';
import isEmpty from 'lodash/isEmpty';

function validateInput(data) {
	const errors = {};

	if (Validator.isEmpty(data.username)) {
		errors.identifier = 'This field is required';
	}
	if (Validator.isEmpty(data.password)) {
		errors.password = 'This field is required';
	}

	return {
		errors,
		isValid: isEmpty(errors),
	};
}

export default validateInput;
