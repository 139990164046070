import React, {useState} from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import UrlTable from '../../controls/UrlTable/UrlTable';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {Grid} from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	margin: {
		marginRight: theme.spacing(2),
	},
	popoverPanel: {
		maxWidth: '70%',
	},
	popover: {
		margin: theme.spacing(2),
	}
}));

const A11yAudit = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;


	const {rule, title, count, urls, onSelectUrl, auditId} = props;
	const classes = useStyles();
	const {wuhcag_summary, wuhcag_detail, wuhcag_tips, wuhcag_what_to_do} = rule;

	const trimText = (text, length) => {
		return text.length > length ? `${text.substr(0, length - 1)}...` : text;
	};

	return <ExpansionPanel>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
			<Chip className={classes.margin} label={count} color="primary"/>
			<Typography className={classes.heading}>
				{trimText(title, 135)}
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Button aria-describedby={id} onClick={handleClick}>Details & Summary</Button>
					<Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
							 className={classes.popoverPanel}>
						<Grid container spacing={5}>
							<Grid item xs={12} className={classes.popover}>
								<Typography component='p' dangerouslySetInnerHTML={{__html: wuhcag_summary}}/>
								<Typography component='p' dangerouslySetInnerHTML={{__html: wuhcag_detail}}/>
								<Typography component='p' dangerouslySetInnerHTML={{__html: wuhcag_tips}}/>
								<Typography component='p' dangerouslySetInnerHTML={{__html: wuhcag_what_to_do}}/>
							</Grid>
						</Grid>
					</Popover>
				</Grid>
				<Grid item xs={12}>
					<UrlTable onSelectUrl={onSelectUrl} label="Found On" urls={urls} auditId={auditId} count={count}/>
				</Grid>
			</Grid>
		</ExpansionPanelDetails>
	</ExpansionPanel>
};

A11yAudit.defaultProps = {
	score: 0,
};

A11yAudit.propTypes = {
	selected: PropTypes.bool.isRequired,
	auditId: PropTypes.string.isRequired,
	scoringMode: PropTypes.string.isRequired,
	score: PropTypes.number,
	title: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	urls: PropTypes.array.isRequired,
	onSelectUrl: PropTypes.func.isRequired,
	onToggleAudit: PropTypes.func.isRequired,
	rule: PropTypes.object.isRequired,
};

export default A11yAudit;
