import React, {useContext, useState} from 'react';
import Redirect from 'react-router-dom/es/Redirect';
import {MainContext} from '../../context/main';
import axios from 'axios';
import {getApiUrl} from '../../utils/config';
import {setAxiosAuthorizationToken} from '../../utils/authorization';
import validateInput from '../../utils/validators';
import {Container} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import jwt from 'jsonwebtoken';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	textField: {
		width: '100%'
	},
	loginGrid: {
		textAlign: 'right'
	},
	paper: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(3),
	}
}));

const LoginPage = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState({identifier: false, password: false});
	const [isLoading, setLoading] = useState(false);
	const {isAuthenticated, setAuthenticated, setCheckingAuth} = useContext(MainContext);
	const classes = useStyles();

	const onSubmit = (e) => {
		e.preventDefault();
		if (isValid()) {
			setErrors({});
			setLoading(true);
			setCheckingAuth(true);
			const userCredentials = {username: username, password: password};
			axios.post(getApiUrl('/wp-json/jwt-auth/v1/token'), userCredentials)
				.then(res => {
					const {token} = res.data;
					localStorage.setItem('token', token);
					setAxiosAuthorizationToken(token);
					setAuthenticated(token);
					const decoded = jwt.decode(token);

					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						'event' : 'login',
						'user_id' : decoded.data.user.id //this number must be replaced with an actual User ID
					})

					setCheckingAuth(false);
				})
				.catch(e => {
					setErrors({
						identifier: '',
						password: '',
						message: e.response.data.message,
					});
					setLoading(false);
					setCheckingAuth(false);
				});
		}
	};

	const isValid = () => {
		const {errors, isValid} = validateInput({username: username, password: password});
		if (!isValid) {
			setErrors(errors);
		}
		return isValid;
	};

	if (isAuthenticated) {
		return <Redirect to={'/'}/>
	}

	return <Container maxWidth="sm" className={classes.root}>
		<Paper className={classes.paper}>
			<form onSubmit={onSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='h4' component='h2'>Login</Typography>
					</Grid>
					<Grid item xs={12}>
						{errors.message && <div className="alert alert-danger"
												dangerouslySetInnerHTML={{__html: errors.message}}/>}
					</Grid>
					<Grid item xs={12}>
						<TextField field="username"
								   className={classes.textField}
								   label="Username / Email"
								   value={username}
								   error={errors.identifier}
								   onChange={e => setUsername(e.target.value)}
								   type="text"/>
					</Grid>
					<Grid item xs={12}>
						<TextField field="password"
								   className={classes.textField}
								   label="Password"
								   value={password}
								   error={errors.password}
								   onChange={e => setPassword(e.target.value)}
								   type="password"/>
					</Grid>
					<Grid item xs={8}>
						<Link href="https://webservices.ellucian.com/wp-login.php?action=lostpassword"
							  rel="noopener noreferrer" target="_blank">
							Reset Password
						</Link>
					</Grid>
					<Grid item xs={4} className={classes.loginGrid}>
						<Button className={classes.textField}
								color='primary'
								type='submit'
								variant='contained'
								disabled={isLoading}>Login</Button>
					</Grid>
				</Grid>
			</form>
		</Paper>
	</Container>
};

export default LoginPage;
