// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import CopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
	copyClipButton: {
		float: 'right'
	},
	code: {
		'& pre': {
			padding: theme.spacing(2),
			overflow: 'auto',
			overflowY: 'hidden',
			background: '#333',
			color: '#fff',
			borderRadius: '4px',
			'& code': {
				lineHeight: 2.5
			}
		},
	},
	code2: {
		'& pre': {
			padding: theme.spacing(1),
			overflow: 'auto',
			overflowY: 'hidden',
			background: '#eee',
			color: '#333',
			borderRadius: '4px',
			'& code': {
				lineHeight: 2.5
			}
		},
	}
}));

export const Code = (props) => {
	const classes = useStyles();
	const [openCopyMessage, setOpenCopyMessage] = useState(false);
	const {codeText, enableCopy, color} = props;
	return <div className={color === 'secondary' ? classes.code2 : classes.code}>
		{enableCopy ? <Snackbar
			anchorOrigin={{vertical: 'top', horizontal: 'center',}}
			open={openCopyMessage}
			autoHideDuration={2000}
			onClose={() => setOpenCopyMessage(false)}
			ContentProps={{'aria-describedby': 'message-id',}}
			message={<span id="message-id">Selector Copied!</span>}/> : null}
		{enableCopy ? <CopyToClipboard text={codeText} onCopy={() => setOpenCopyMessage(true)}>
			<IconButton className={classes.copyClipButton} aria-label="Copy to clipboard">
				<CopyIcon/>
			</IconButton>
		</CopyToClipboard> : null}
		<pre><code>{codeText}</code></pre>
	</div>
};

Code.propTypes = {
	codeText: PropTypes.string.isRequired,
	enableCopy: PropTypes.bool,
	color: PropTypes.string
};
