import LighthouseReportBase from './LighthouseReportBase';
import React from 'react';

const LighthouseReportBestPractices = (props) => {
	return <LighthouseReportBase
		{...props}
		reportFriendlyName={'Best Practices'}
		reportName={'_summary_best_practices'}
		reportCategory={'best-practices'}
		reportIcon={'check-circle'}/>;
};

export default LighthouseReportBestPractices;
