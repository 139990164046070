import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
// noinspection ES6CheckImport
import BrokenLinkAudit from './BrokenLinkAudit';
import ReportWrap from '../../controls/ReportWrap/ReportWrap';
import {getApiUrl} from '../../../utils/config';
import UrlSearch from '../../controls/UrlSearch/UrlSearch';
import {MainContext} from '../../../context/main';
import {Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LighthouseAuditSummary from '../Lighthouse/LighthouseAuditSummary';
import ReportNaErrorMessage from '../../controls/ReportNaErrorMessage';
import LoadingMessage from '../../controls/LoadingMessage';
import LinkIcon from '@material-ui/icons/Link';
import {Code} from '../../controls/Code';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(1),
	},
	chip: {
		margin: theme.spacing(1),
	},
	paper: {
		padding: theme.spacing(3, 2),
	},
	title: {
		textAlign: 'right',
		paddingTop: theme.spacing(2)
	},
	linkButton: {
		height: '100%',
		marginLeft: '10px'
	},
	searchButton: {
		height: '100%',
	},
	urlField: {
		width: '100%',
		margin: 0
	},
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
	toggleButtons: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}));

const BrokenLinksReport = (props) => {
	const [isLoaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [detailLoading, setDetailLoading] = useState(false);
	const [selectedUrl, setSelectedUrl] = useState(null);
	const [selectedUrlInfo, setSelectedUrlInfo] = useState(null);
	const [searching, setSearching] = useState(false);
	const [reportSummary, setReportSummary] = useState(null);
	const [reportDetails, setReportDetails] = useState(null);
	const [selectedAuditId, setSelectedAuditId] = useState('');
	const classes = useStyles();

	const {domainId} = props.match.params;
	const {reportsUrl, urls, getUrls, isAuthenticated} = useContext(MainContext);

	useEffect(() => {

		document.title = `Broken Links - ${domainId}`;

		if (!reportSummary && isAuthenticated) {

			window.dataLayer.push(
				{
					'event': 'viewReport',
					'report' : 'Broken Links',
					'client': domainId
				}
			);

			const url = `${reportsUrl}?domain=${domainId}&file=/broken_links/_summary.json`;
			axios.get(getApiUrl(url)).then(r1 => {
				setReportSummary(r1.data);
				setLoaded(true);
				setError(false);
			}).catch(error => {
				setError(error);
				setLoaded(true);
			});
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated) {
			getUrls(domainId);
		}
	}, [isAuthenticated]);

	const onSelectUrl = (url, auditKey, index, total) => {
		const {name} = url;
		const endpointUrl = `${reportsUrl}?domain=${domainId}&file=/broken_links/${name}.json`;
		setDetailLoading(true);
		setActiveTab(1);
		axios.get(getApiUrl(endpointUrl))
			.then(response => {
				setReportDetails(response.data);
				setDetailLoading(false);
				setSelectedUrl(url);
				setSelectedUrlInfo(auditKey ? {auditKey: auditKey, index: index, total: total} : null);
				setError(false);
			})
			.catch(error => {
				setError(error);
				setLoaded(true);
				setSelectedUrlInfo(null);
			});
	};

	const onToggleAudit = audit => {
		setSelectedAuditId(selectedAuditId === '' ? audit : '');
	};

	const onToggleSearch = () => {
		setSearching(!searching);
	};

	const onAnyClick = () => {
		if (searching) {
			setSearching(false);
		}
	};

	const createDetailReport = () => {
		if (reportDetails !== null) {
			const failedElement = reportDetails.filter(item => {
				const {working} = item;
				return !working;
			}).map((issue, key) => <Grid item xs={12} key={key}>
				<Paper className={classes.paper}>
					<Code codeText={issue.title}/>
					<Chip label={issue.type === 'a' ? 'Broken Link' : 'Broken Image'}
						  className={classes.chip}
						  color="secondary"/>
					<Code codeText={issue.url} color='secondary' enableCopy={true}/>
					<Code codeText={issue.selector} color='secondary' enableCopy={true}/>
				</Paper>
			</Grid>);

			return reportDetails && selectedUrl ? <ReportWrap>
				<Grid container spacing={1}>
					{failedElement}
				</Grid>
			</ReportWrap> : null
		}
		return [];
	};

	const createReport = () => {
		if (!reportSummary) {
			return null;
		}
		const {scanned, name, description, brokenLinks} = reportSummary;
		const failedElements = Object.keys(brokenLinks.issues)
			.map(key => brokenLinks.issues[key])
			.filter(audit => audit !== undefined)
			.sort((a, b) => {
				if (a.reportedOn.length < b.reportedOn.length) {
					return 1;
				} else if (a.reportedOn.length > b.reportedOn.length) {
					return -1;
				}
				return 0;
			})
			.filter((a, index) => index < 10)
			.map((a, index) => {
				const urlsNew = a.reportedOn
					.map(file => {
						const us = urls.filter(url => url.name === file.replace('.json', ''));
						if (us.length > 0) {
							return us[0];
						}
						return null;
					})
					.filter(url => url !== null)
					.sort((a, b) => a.url.localeCompare(b.url));

				return <BrokenLinkAudit
					key={`${a.key}-${index}`}
					auditId={a.key}
					title={a.title}
					count={a.reportedOn.length}
					urls={urlsNew ? urlsNew : []}
					onSelectUrl={onSelectUrl}
					onToggleAudit={onToggleAudit}
					selected={selectedAuditId === a.key}
					brokenUrl={a.url}
					selectors={a.selectors}/>
			});

		return <LighthouseAuditSummary
			scanned={scanned}
			score={0}
			name={`${name} Summary`}
			description={description}
			title={`${brokenLinks.total} total broken found on ${urls ? urls.length : 0} Pages. Common issues below.`}>
			{failedElements}
		</LighthouseAuditSummary>
	};

	const handleClose = () => {
		setActiveTab(0);
	};

	const prevUrl = () => {
		if (reportSummary && selectedUrlInfo) {
			const {brokenLinks} = reportSummary;
			const url = Object.keys(brokenLinks.issues)
				.map(key => brokenLinks.issues[key])
				.filter(audit => audit !== undefined)
				.sort((a, b) => {
					if (a.reportedOn.length < b.reportedOn.length) {
						return 1;
					} else if (a.reportedOn.length > b.reportedOn.length) {
						return -1;
					}
					return 0;
				})
				.filter(audit => audit.key === selectedUrlInfo.auditKey)
				.map((a) => {
					const urlsNew = a.reportedOn
						.map(file => {
							const us = urls.filter(url => url.name === file.replace('.json', ''));
							if (us.length > 0) {
								return us[0];
							}
							return null;
						})
						.filter(url => url !== null)
						.sort((a, b) => a.url.localeCompare(b.url));
					if (urlsNew.length > (selectedUrlInfo.index - 1) && (selectedUrlInfo.index - 1) > -1) {
						return urlsNew[selectedUrlInfo.index - 1];
					}
					return null;
				});
			if (url.length > 0 && url[0] !== null) {
				onSelectUrl(url[0], selectedUrlInfo.auditKey, selectedUrlInfo.index - 1, selectedUrlInfo.total)
			}
		}
	};

	const nextUrl = () => {
		if (reportSummary && selectedUrlInfo) {
			const {brokenLinks} = reportSummary;
			const url = Object.keys(brokenLinks.issues)
				.map(key => brokenLinks.issues[key])
				.filter(audit => audit !== undefined)
				.sort((a, b) => {
					if (a.reportedOn.length < b.reportedOn.length) {
						return 1;
					} else if (a.reportedOn.length > b.reportedOn.length) {
						return -1;
					}
					return 0;
				})
				.filter(audit => audit.key === selectedUrlInfo.auditKey)
				.map((a) => {
					const urlsNew = a.reportedOn
						.map(file => {
							const us = urls.filter(url => url.name === file.replace('.json', ''));
							if (us.length > 0) {
								return us[0];
							}
							return null;
						})
						.filter(url => url !== null)
						.sort((a, b) => a.url.localeCompare(b.url));
					if (urlsNew.length > (selectedUrlInfo.index + 1)) {
						return urlsNew[selectedUrlInfo.index + 1];
					}
					return null;
				});
			if (url.length > 0 && url[0] !== null) {
				onSelectUrl(url[0], selectedUrlInfo.auditKey, selectedUrlInfo.index + 1, selectedUrlInfo.total)
			}
		}
	};

	if (error) {
		return <ReportNaErrorMessage/>;
	}

	return isLoaded ? <div className={classes.root}>
		{createReport()}
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={activeTab === 1} maxWidth='lg'
				fullWidth={true}
				classes={{paper: classes.dialogPaper}}
				scroll='paper'>
			<DialogTitle id="simple-dialog-title">
				<Grid container>
					<Grid item xs={1}>
						<Button color="primary" aria-label="Find a Page" className={classes.searchButton}
								onClick={onToggleSearch}>
							<SearchIcon/>
						</Button>
						<UrlSearch
							onToggleSearch={onToggleSearch}
							cancel={onAnyClick}
							open={searching}
							urls={urls ? urls : []}
							onSelectUrl={onSelectUrl}/>
					</Grid>
					<Grid item xs={8}>

						<Tooltip title={selectedUrl ? selectedUrl.name : 'no page selected'} interactive>
							<TextField
								value={selectedUrl ? selectedUrl.url : 'no page selected'}
								disabled={true}
								className={classes.urlField}
								margin="normal"
								variant="outlined"/>
						</Tooltip>
					</Grid>
					<Grid item xs={1}>
						{selectedUrl ?
							<Button href={selectedUrl.url} target="_blank" rel="noopener noreferer"
									className={classes.linkButton}>
								<LinkIcon/>
							</Button> : null}
					</Grid>
					<Grid item xs={2}>
						<div className={classes.title}>
							Broken Links
						</div>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers={true}>
				{detailLoading ? <LoadingMessage/> : createDetailReport()}
			</DialogContent>
			<DialogActions>
				<Grid container>
					<Grid item xs={4}>
						<Button aria-label="Previous page" onClick={prevUrl}>
							<ArrowBackIcon/>
						</Button>
						<Button aria-label="Next page" onClick={nextUrl}>
							<ArrowForwardIcon/>
						</Button>
					</Grid>
					<Grid item xs={4} style={{textAlign: 'center'}}/>
					<Grid item xs={4} style={{textAlign: 'right'}}>
						<Button onClick={handleClose} color="primary">
							Close
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	</div> : <LoadingMessage/>
};

export default BrokenLinksReport;
