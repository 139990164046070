import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {Typography} from '@material-ui/core';

const ReportNaErrorMessage = () => {
	return <Grid container spacing={2}>
		<Grid item xs={12}>
			<Card>
				<CardHeader title="Report Not Available"/>
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						This site has not been scanned yet. Please check back later.
					</Typography>
				</CardContent>
			</Card>
		</Grid>
	</Grid>;
};

export default ReportNaErrorMessage;
