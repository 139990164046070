import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {Grid} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import LoadingMessage from '../LoadingMessage';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import {Code} from '../Code';

const useStyles = makeStyles(theme => ({
	root: {
		flex: 1
	},
	paper: {
		padding: theme.spacing(3, 2),
	}
}));

/**
 * This component will render the url detail.
 */
const UrlDetail = (props) => {
	const classes = useStyles();
	const {url, tab} = props;

	const messageCodeUrl = (issue) => {
		const code = issue.code.split('.')[4];
		return `https://www.w3.org/TR/WCAG20-TECHS/${code}`;
	};

	const issues = props.issues.reduce((acc, issue) => {
		const {typeCode} = issue;
		if (typeCode === 1) {
			acc.errors.push(issue);
		} else if (typeCode === 2) {
			acc.warnings.push(issue);
		} else {
			acc.notices.push(issue);
		}
		return acc;
	}, {errors: [], warnings: [], notices: [],});

	let visibleIssues;
	if (tab === 1) {
		visibleIssues = issues.errors;
	} else if (tab === 2) {
		visibleIssues = issues.warnings;
	} else if (tab === 3) {
		visibleIssues = issues.notices;
	}

	return props.issues !== null && url !== null ? <Grid container spacing={2} className={classes.root}>
		{visibleIssues.map((issue, key) => <Grid item xs={12} key={key + url.name}>
			<Paper className={classes.paper}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant='h6' component='h4'>
							<a href={messageCodeUrl(issue)} target="_blank" rel="noopener noreferer">
								{issue.code}
							</a>
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>{issue.message}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Code codeText={issue.context}/>
					</Grid>
					<Grid item xs={12}>
						<Code codeText={issue.selector} enableCopy={true} color='secondary'/>
					</Grid>
				</Grid>
			</Paper>
		</Grid>)}
	</Grid> : <LoadingMessage/>
};

UrlDetail.propTypes = {
	url: PropTypes.object,
	issues: PropTypes.array.isRequired,
};

export default UrlDetail;
