import React from 'react';

const ELLIPSIS = '\u2026';
const NBSP = '\xa0';

const RATINGS = {
	PASS: {label: 'pass', minScore: 75},
	AVERAGE: {label: 'average', minScore: 45},
	FAIL: {label: 'fail'},
};

class LighthouseUtilities {
	/**
	 * Convert a score to a rating label.
	 * @param {number} score
	 * @return {string}
	 */
	static calculateRating(score) {
		let rating = RATINGS.FAIL.label;
		if (score >= RATINGS.PASS.minScore) {
			rating = RATINGS.PASS.label;
		} else if (score >= RATINGS.AVERAGE.minScore) {
			rating = RATINGS.AVERAGE.label;
		}
		return rating;
	}

	/**
	 * Format number.
	 * @param {number} number
	 * @param {number=} decimalPlaces Number of decimal places to include. Defaults to 1.
	 * @return {string}
	 */
	static formatNumber(number, decimalPlaces = 1) {
		return number.toLocaleString(undefined, {maximumFractionDigits: decimalPlaces});
	}

	/**
	 * @param {number} size
	 * @param {number=} decimalPlaces Number of decimal places to include. Defaults to 2.
	 * @return {string}
	 */
	static formatBytesToKB(size, decimalPlaces = 2) {
		const kbs = (size / 1024).toLocaleString(undefined, {maximumFractionDigits: decimalPlaces});
		return `${kbs}${NBSP}KB`;
	}

	/**
	 * @param {number} ms
	 * @param {number=} granularity Controls how coarse the displayed value is, defaults to 10
	 * @return {string}
	 */
	static formatMilliseconds(ms, granularity = 10) {
		const coarseTime = Math.round(ms / granularity) * granularity;
		return `${coarseTime.toLocaleString()}${NBSP}ms`;
	}

	/**
	 * Format time.
	 * @param {string} date
	 * @return {string}
	 */
	static formatDateTime(date) {
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			timeZoneName: 'short',
		};
		let formatter = new Intl.DateTimeFormat('en-US', options);

		// Force UTC if runtime timezone could not be detected.
		// See https://github.com/GoogleChrome/lighthouse/issues/1056
		const tz = formatter.resolvedOptions().timeZone;
		if (!tz || tz.toLowerCase() === 'etc/unknown') {
			options.timeZone = 'UTC';
			formatter = new Intl.DateTimeFormat('en-US', options);
		}
		return formatter.format(new Date(date));
	}

	/**
	 * Converts a time in seconds into a duration string, i.e. `1d 2h 13m 52s`
	 * @param {number} timeInSeconds
	 * @param {string=} zeroLabel
	 * @return {string}
	 */
	static formatDuration(timeInSeconds, zeroLabel = 'None') {
		if (timeInSeconds === 0) {
			return zeroLabel;
		}

		let duration = timeInSeconds;

		/** @type {!Array<string>} */
		const parts = [];
		const unitLabels = /** @type {!Object<string, number>} */ ({
			d: 60 * 60 * 24,
			h: 60 * 60,
			m: 60,
			s: 1,
		});

		Object.keys(unitLabels).forEach(label => {
			const unit = unitLabels[label];
			const numberOfUnits = Math.floor(duration / unit);
			if (numberOfUnits > 0) {
				duration -= numberOfUnits * unit;
				parts.push(`${numberOfUnits}\xa0${label}`);
			}
		});

		return parts.join(' ');
	}

	/**
	 * @param {!URL} parsedUrl
	 * @param {{numPathParts: (number|undefined), preserveQuery: (boolean|undefined), preserveHost: (boolean|undefined)}=} options
	 * @return {string}
	 */
	static getURLDisplayName(parsedUrl, params) {
		const options = params || {};
		const numPathParts = options.numPathParts !== undefined ? options.numPathParts : 2;
		const preserveQuery = options.preserveQuery !== undefined ? options.preserveQuery : true;
		const preserveHost = options.preserveHost || false;

		let name;

		if (parsedUrl.protocol === 'about:' || parsedUrl.protocol === 'data:') {
			// Handle 'about:*' and 'data:*' URLs specially since they have no path.
			name = parsedUrl.href;
		} else {
			name = parsedUrl.pathname;
			const parts = name.split('/').filter(part => part.length);
			if (numPathParts && parts.length > numPathParts) {
				name = ELLIPSIS + parts.slice(-1 * numPathParts).join('/');
			}

			if (preserveHost) {
				name = `${parsedUrl.host}/${name.replace(/^\//, '')}`;
			}
			if (preserveQuery) {
				name = `${name}${parsedUrl.search}`;
			}
		}

		const MAX_LENGTH = 64;
		// Always elide hexadecimal hash
		name = name.replace(/([a-f0-9]{7})[a-f0-9]{13}[a-f0-9]*/g, `$1${ELLIPSIS}`);
		// Also elide other hash-like mixed-case strings
		name = name.replace(/([a-zA-Z0-9-_]{9})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9-_]{10,}/g, `$1${ELLIPSIS}`);
		// Also elide long number sequences
		name = name.replace(/(\d{3})\d{6,}/g, `$1${ELLIPSIS}`);
		// Merge any adjacent ellipses
		name = name.replace(/\u2026+/g, ELLIPSIS);

		// Elide query params first
		if (name.length > MAX_LENGTH && name.includes('?')) {
			// Try to leave the first query parameter intact
			name = name.replace(/\?([^=]*)(=)?.*/, `?$1$2${ELLIPSIS}`);

			// Remove it all if it's still too long
			if (name.length > MAX_LENGTH) {
				name = name.replace(/\?.*/, `?${ELLIPSIS}`);
			}
		}

		// Elide too long names next
		if (name.length > MAX_LENGTH) {
			const dotIndex = name.lastIndexOf('.');
			if (dotIndex >= 0) {
				name = `${
					name.slice(0, MAX_LENGTH - 1 - (name.length - dotIndex))
					// Show file extension
					}${ELLIPSIS}${name.slice(dotIndex)}`;
			} else {
				name = name.slice(0, MAX_LENGTH - 1) + ELLIPSIS;
			}
		}

		return name;
	}

	/**
	 * Split a URL into a file and hostname for easy display.
	 * @param {string} url
	 * @return {{file: string, hostname: string}}
	 */
	static parseURL(url) {
		const parsedUrl = new URL(url);
		return {file: LighthouseUtilities.getURLDisplayName(parsedUrl), hostname: parsedUrl.hostname};
	}

	/**
	 * @param {number} startTime
	 * @param {number} endTime
	 * @return {string}
	 */
	static chainDuration(startTime, endTime) {
		return LighthouseUtilities.formatNumber((endTime - startTime) * 1000);
	}

	static convertMarkdownCodeSnippets(text) {
		// const element = ''
		const parts = text.split(/`(.*?)`/g); // Split on markdown code slashes
		const elements = [];

		let index = 0;
		while (parts.length) {
			// Pop off the same number of elements as there are capture groups.
			const [preambleText, codeText] = parts.splice(0, 2);
			elements.push(<span key={`preamble-${index}`}>{preambleText}</span>);
			if (codeText) {
				index += 1;
				const pre = <code key={`code-${index}`}>{codeText}</code>;
				elements.push(pre);
			}
		}

		return <span>{elements}</span>;
	}

	static convertMarkdownLinkSnippets(text) {
		const elements = [];
		// Split on markdown links (e.g. [some link](https://...)).
		const parts = text.split(/\[([^\]]*?)\]\((https?:\/\/.*?)\)/g);

		let index = 0;
		while (parts.length) {
			index += 1;
			// Pop off the same number of elements as there are capture groups.
			const [preambleText, linkText, linkHref] = parts.splice(0, 3);
			elements.push(<span key={`preamble-${index}`}>{preambleText}</span>);

			// Append link if there are any.
			if (linkText && linkHref) {
				elements.push(
					<a key={`link-${index}`} href={linkHref} target="_blank" rel="noopener">
						{linkText}
					</a>
				);
			}
		}

		return <span>{elements}</span>;
	}
}

export default LighthouseUtilities;
