import React, {useState, useEffect} from 'react';
import {Route} from 'react-router-dom';
import clsx from 'clsx';

import SidebarContainer from './components/sidebar/SidebarContainer';
import LoginPage from './components/auth/LoginPage';
import Home from './components/home/Home';
import Dashboard from './components/reports/Dashboard/Dashboard';
import A11yReport from './components/reports/A11y/A11yReport';
import BrokenLinksReport from './components/reports/BrokenLinks/BrokenLinksReport';
import LighthousePerformance from './components/reports/Performance/LighthousePerformance';
import Content from './components/reports/Content/Content';
import LogoutPage from './components/auth/LogoutPage';
import TopBarContainer from './components/topbar/TopBarContainer';
import LighthouseReportPWA from './components/reports/Lighthouse/LighthouseReportPWA';
import LighthouseReportAccessibility from './components/reports/Lighthouse/LighthouseReportAccessibility';
import LighthouseReportBestPractices from './components/reports/Lighthouse/LighthouseReportBestPractices';
import LighthouseReportSEO from './components/reports/Lighthouse/LighthouseReportSEO';
import packageInfo from '../package.json';
import SecurityReport from './components/reports/Security/SecurityReport';
import {MainContext} from './context/main';
import {setAxiosAuthorizationToken, hasSessionExpired} from './utils/authorization';
import axios from 'axios';
import {getApiUrl} from './utils/config';

import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import {AppBar, Button, Toolbar} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HelpIcon from '@material-ui/icons/ReportProblem';
import jwt from 'jsonwebtoken';

const theme = createMuiTheme({
	palette: {
		primary: {main: '#682673'},
		secondary: {main: '#462F89'},
	},
});

const drawerWidth = 260;
const useStyles = makeStyles(() => ({
	appFooter: {
		top: 'auto',
		bottom: 0,
		zIndex: theme.zIndex.drawer + 1,
	},
	helpButton: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		'& svg': {
			marginRight: theme.spacing(1)
		}
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));


export default () => {
	const [sites, setSites] = useState(null);
	const [urls, setUrls] = useState([]);
	const [domain, setDomain] = useState(null);
	const [favicon, setFavicon] = useState(null);
	const [isAuthenticated, setAuthenticated] = useState(null);
	const [isCheckingAuth, setCheckingAuth] = useState(true);
	const [isMenuOpen, setMenuOpen] = useState(true);
	const [reportsUrl] = useState('/wp-json/surveyor/v2/reports');
	const classes = useStyles();

	useEffect(() => {
		if (localStorage.token && !hasSessionExpired()) {
			setAxiosAuthorizationToken(localStorage.token);
			setAuthenticated(localStorage.token);
			setCheckingAuth(false);

			const decoded = jwt.decode(localStorage.token);

			window.dataLayer.push({
				'user_id' : decoded.data.user.id //this number must be replaced with an actual User ID
			});


		} else if (!localStorage.token) {
			setCheckingAuth(false);
		}
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			getSites();
		}
	}, [isAuthenticated]);

	const getUrls = (domainId) => {
		return new Promise((resolve, reject) => {
			if (urls.length > 0 && domainId === domain) {
				resolve(urls);
			} else {
				const endpoint = getApiUrl(`${reportsUrl}?domain=${domainId}&file=/urls/urls.json`);
				setDomain(domainId);
				axios.get(endpoint)
					.then(response => {
						setUrls(response.data);
						resolve(response.data);
					})
					.catch(e => reject(e));
			}
		});
	};

	const getSites = () => {
		return new Promise((resolve, reject) => {
			if (sites) {
				resolve(sites);
			} else {
				axios.get(getApiUrl('/wp-json/wp/v2/sites') + '?per_page=50')
					.then(response => {
						setSites(response.data);
						resolve(response.data);
					})
					.catch(e => reject(e));
			}
		});
	};

	const handleOpenMenu = () => {
		setMenuOpen(!isMenuOpen);
	};

	return <MainContext.Provider
		value={{
			reportsUrl,
			sites,
			urls,
			getUrls,
			isAuthenticated,
			setAuthenticated,
			setCheckingAuth,
			favicon,
			setFavicon
		}}>
		<ThemeProvider theme={theme}>
			<Route exact render={(props) => <TopBarContainer {...props}/>} path="/"/>
			<Route exact render={(props) => <TopBarContainer {...props}/>} path="/login"/>
			<Route render={(props) => <TopBarContainer {...props} handleMenuOpen={handleOpenMenu}/>}
				   path="/reports/:siteId/:domainId/:report"/>
			<Toolbar/>
			<Route render={(props) => <SidebarContainer {...props} isMenuOpen={isMenuOpen}/>}
				   path="/reports/:siteId/:domainId/:report"/>
			{(!isAuthenticated && !isCheckingAuth) || hasSessionExpired() ? <Route path="/" component={LoginPage}/> :
				<React.Fragment>
					<Route exact path="/" component={Home}/>
					<Route exact path='/login' component={LoginPage}/>
					<Route path="/logout" component={LogoutPage}/>
					<main className={clsx(classes.content, {[classes.contentShift]: !isMenuOpen,})}>
						<Route exact path='/reports/:siteId/:domainId/dashboard' component={Dashboard} />
						<Route path="/reports/:siteId/:domainId/lighthouse-accessibility"
							   component={LighthouseReportAccessibility}/>
						<Route path='/reports/:siteId/:domainId/best-practices'
							   component={LighthouseReportBestPractices}/>
						<Route path="/reports/:siteId/:domainId/pwa" component={LighthouseReportPWA}/>
						<Route path='/reports/:siteId/:domainId/accessibility' component={A11yReport}/>
						<Route path='/reports/:siteId/:domainId/broken-links' component={BrokenLinksReport}/>
						<Route path="/reports/:siteId/:domainId/content" component={Content}/>
						<Route path='/reports/:siteId/:domainId/seo' component={LighthouseReportSEO}/>
						<Route path="/reports/:siteId/:domainId/performance" component={LighthousePerformance}/>
						<Route path="/reports/:siteId/:domainId/security" component={SecurityReport}/>
					</main>
				</React.Fragment>
			}
			<Toolbar/>
			<AppBar position="fixed" color="default" className={classes.appFooter}>
				<Toolbar>
					<Button href="https://webservices.ellucian.com/" target="_blank" rel="noopener noreferrer">
						By Ellucian Web Services
					</Button>
					<Button href="https://gitlab.com/ellucianwebservices/surveyor/surveyor/issues" target="_blank"
							className={classes.helpButton}
							rel="noopener noreferrer" variant="contained" color="primary">
						<HelpIcon/>
						Report Issues
					</Button>
					v{packageInfo.version}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	</MainContext.Provider>
};
