import LighthouseReportBase from './LighthouseReportBase';
import React from 'react';

const LighthouseReportPWA = (props) => {
	return <LighthouseReportBase
		{...props}
		reportFriendlyName={'Progressive Web App'}
		reportName={'_summary_progressive_web_app'}
		reportCategory={'PWA'}
		reportIcon={'file-code'}/>;
};

export default LighthouseReportPWA
