import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import LighthouseUtilities from '../../../utils/lighthouse';
import UrlTable from '../../controls/UrlTable/UrlTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Chip from '@material-ui/core/Chip';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	margin: {
		marginRight: theme.spacing(2),
	}
}));

const LighthouseAuditSummaryDetail = (props) => {
	const {helpText, title, count, urls, onSelectUrl, auditId} = props;
	const classes = useStyles();

	return <ExpansionPanel>
		<ExpansionPanelSummary
			expandIcon={<ExpandMoreIcon/>}
			aria-controls="panel1a-content"
			id="panel1a-header">
			<Chip className={classes.margin} label={count} color="primary"/>
			<Typography className={classes.heading}>
				{LighthouseUtilities.convertMarkdownLinkSnippets(title)}
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography>
						{LighthouseUtilities.convertMarkdownLinkSnippets(helpText)}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<UrlTable onSelectUrl={onSelectUrl} label="Found On"urls={urls} auditId={auditId} count={count}/>
				</Grid>
			</Grid>
		</ExpansionPanelDetails>
	</ExpansionPanel>
};

LighthouseAuditSummaryDetail.propTypes = {
	selected: PropTypes.bool.isRequired,
	auditId: PropTypes.string.isRequired,
	scoringMode: PropTypes.string.isRequired,
	helpText: PropTypes.string.isRequired,
	score: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	urls: PropTypes.array.isRequired,
	onSelectUrl: PropTypes.func.isRequired,
	onToggleAudit: PropTypes.func.isRequired,
};

export default LighthouseAuditSummaryDetail;
