import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import NumberFormat from "react-number-format";
import { getApiUrl } from "../../../utils/config";
import { MainContext } from "../../../context/main";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import ReportNaErrorMessage from "../../controls/ReportNaErrorMessage";
import moment from "moment";

const useStyles = makeStyles(() => ({
	card: {
		textAlign: "center"
	}
}));

const Dashboard = (props) => {
	const { siteId, domainId } = props.match.params;
	const { reportsUrl, isAuthenticated, setFavicon } = useContext(MainContext);

	const [report, setReport] = useState(null);
	const [error, setError] = useState(null);
	const classes = useStyles();

	useEffect(() => {

		document.title = `Dashboard - ${domainId}`;

		if (isAuthenticated && siteId && domainId && !report) {
			loadReport(siteId, domainId);
		}
	});

	const loadReport = (site, domain) => {
		const url = `${reportsUrl}?domain=${domain}&file=/reports/dashboard.json`;
		axios.get(getApiUrl(url)).then(
			response => {
				setReport(response.data);
				setFavicon(response.data.favicon);
				setError(null);
			},
			error => {
				setReport(null);
				setFavicon(null);
				setError(error);
			}
		);
	};

	if (report !== null) {
		const {
			option,
			scanFinished,
			siteResponsivePercent,
			averagePageLoadTime,
			siteHasSslPercent,
			a11yErrorCount,
			a11yAverageErrors,
			a11yAverageWarnings,
			brokenLinksFoundCount,
			pagesScanned,
			brokenLinksPerPage,
			similarPagesFound,
			pagesOutOfDateCount,
			averageReadingTime,
			numberOfMisspellings
		} = report;
		return <Grid container spacing={2}>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Overview"/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs>
								<Card className={classes.card}>
									<CardHeader subheader="Scan finished"/>
									<CardContent>
										<Typography variant="h5" component="h2">
												{scanFinished ? moment(scanFinished).format("MMMM Do YYYY, h:mm a") : "N/A"}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardHeader subheader="Pages Scanned"/>
									<CardContent>
										<Typography variant="h5" component="h2">
												{pagesScanned}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card>
					<CardHeader title="Performance"/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs>
								<Card className={classes.card}>
									<CardHeader subheader="Responsive"/>
									<CardContent>
										<Typography variant="h4" component="h2">
											{siteResponsivePercent.toFixed() + "%"}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardHeader subheader="Load Time"/>
									<CardContent>
										<Typography variant="h4" component="h2">
											{[averagePageLoadTime.toFixed(2), <small key={`1`}>ms</small>]}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardHeader subheader="Site Security"/>
									<CardContent>
										<Typography variant="h4" component="h2">
											{[siteHasSslPercent.toFixed() + "%", <small key={`1`}>SSL</small>]}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title='Accessibility'
											subheader={`Accessibility tests were run using the ${option && option.a11y && option.a11y.standard ? option.a11y.standard : "WCAG2"} standard.`}/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Total Errors
										</Typography>
										<Typography variant="h4" component="h2">
											<NumberFormat value={a11yErrorCount} displayType={"text"}
																		thousandSeparator={true}/>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Page Errors
										</Typography>
										<Typography variant="h4" component="h2">
											{a11yAverageErrors.toFixed()}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Page Warnings
										</Typography>
										<Typography variant="h4" component="h2">
											{a11yAverageWarnings.toFixed()}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title='Broken Links'/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Total Broken Links
										</Typography>
										<Typography variant="h4" component="h2">
											{brokenLinksFoundCount}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Page Broken Links
										</Typography>
										<Typography variant="h4" component="h2">
											{brokenLinksPerPage.toFixed()}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<Grid item xs={12}>
				<Card>
					<CardHeader title='Content'/>
					<CardContent>
						<Grid container spacing={2}>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Similar Pages
										</Typography>
										<Typography variant="h4" component="h2">
											{similarPagesFound ? similarPagesFound : `NaN`}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Out-of-Date
										</Typography>
										<Typography variant="h4" component="h2">
											{pagesOutOfDateCount ? pagesOutOfDateCount : `NaN`}
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Reading Time
										</Typography>
										<Typography variant="h4" component="h2">
											{averageReadingTime ? averageReadingTime.toFixed() : 0}
											<small key={`1`}>s/pg</small>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
							<Grid item xs>
								<Card className={classes.card}>
									<CardContent>
										<Typography color="textSecondary" gutterBottom>
											Misspellings
										</Typography>
										<Typography variant="h4" component="h2">
											{numberOfMisspellings ? numberOfMisspellings : 0}
											<small key={`1`}>s/pg</small>
										</Typography>
									</CardContent>
								</Card>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>;
	}
	if (error) {
		return <ReportNaErrorMessage/>;
	}

	return null;
};

export default Dashboard;
