import React, {useState} from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import paginate from 'paginate-array';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import {TableHead} from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing(3),
		overflowX: 'auto',
	},
	table: {
		minWidth: 650,
	},
	paging: {
		margin: theme.spacing(2),
		'& Button': {
			marginRight: theme.spacing(2)
		}
	},
	urlButton: {
		textTransform: 'lowercase',
		width: '100%',
		textAlign: 'left',
		justifyContent: 'left'
	}
}));

const UrlTable = (props) => {
	const {urls, label, auditId, count} = props;
	const numItemsPerPage = 10;
	const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const classes = useStyles();

	const onNextPage = () => setCurrentPageNumber(currentPageNumber + 1);
	const onPreviousPage = () => setCurrentPageNumber(currentPageNumber - 1 >= 0 ? currentPageNumber - 1 : 1);
	const onSelectUrl = (url, auditId, index, count) => props.onSelectUrl(url, auditId, index, count);

	const collection = paginate(urls, currentPageNumber, numItemsPerPage);

	return <Paper className={classes.root}>
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					<TableCell>{label}</TableCell>
					<TableCell/>
				</TableRow>
			</TableHead>
			<TableBody>
				{collection.data.map((data, index) => (
					<TableRow key={data.name}>
						<TableCell>
							<Button className={classes.urlButton}
									onClick={() => onSelectUrl(data, auditId, index, count)}>
								{data.url}
							</Button>
						</TableCell>
						<TableCell>
							<Link href={data.url} target="_blank" rel="noopener noreferer">
								<LinkIcon/>
							</Link>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
		{collection.totalPages > 1 ? <nav aria-label="Pagination" className={classes.paging}>
			<Button onClick={onPreviousPage} disabled={currentPageNumber === 1} aria-label="Previous page">
				<ArrowBackIcon/>
			</Button>
			<Button onClick={onNextPage} disabled={collection.data.length < numItemsPerPage} aria-label="Next page">
				<ArrowForwardIcon/>
			</Button>
		</nav> : null}
	</Paper>
};

UrlTable.propTypes = {
	urls: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	onSelectUrl: PropTypes.func.isRequired,
};

export default UrlTable;
