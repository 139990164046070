import axios from 'axios';
import jwt from 'jsonwebtoken';

const Authorization = 'Authorization';

export const setAxiosAuthorizationToken = (token) => {
	let {common} = axios.defaults.headers;
	if (token) {
		common[Authorization] = `Bearer ${token}`;
	} else {
		delete common[Authorization];
	}
};

export const hasSessionExpired = () => {
	if (localStorage.token) {
		const decoded = jwt.decode(localStorage.token);
		const {exp} = decoded;
		const now = new Date();
		const tokenExpires = new Date(exp * 1000);
		return now > tokenExpires;
	}
	return false;
};
