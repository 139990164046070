import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../common/Icon';

const ReportTabs = (props) => {
	const {activeTab, showDetailsTab, onTabSelect, searching, onToggleSearch} = props;
	return <ul className="tabs">
		<li className={`tabs-title ${activeTab === 1 ? 'is-active' : ''}`}>
			<a role="button" data-tabs-target="panel1" href="#panel1" onClick={e => onTabSelect(1, e)}>
				{activeTab === 2 ? <Icon icon="chevron-circle-left"/> : <Icon icon="boxes"/>} Site Summary
			</a>
		</li>
		{!showDetailsTab ?  null : (
			<li className={`tabs-title ${activeTab === 2 ? 'is-active' : ''}`}>
				<a role="button" href="#panel2" onClick={e => onTabSelect(2, e)}>
					<Icon icon="box"/> Details
				</a>
			</li>
		)}
		<li className={`tabs-title tabs-right ${searching === 3 ? 'is-active' : ''}`}>
			<a role="button" href="#panel3" onClick={onToggleSearch}>
				<Icon icon="search"/> Find a Page
			</a>
		</li>
	</ul>
};

ReportTabs.propTypes = {
	activeTab: PropTypes.number.isRequired,
	searching: PropTypes.bool.isRequired,
	showDetailsTab: PropTypes.bool.isRequired,
	onTabSelect: PropTypes.func.isRequired,
	onToggleSearch: PropTypes.func.isRequired,
};

export default ReportTabs;
