import React from 'react';
import PropTypes from 'prop-types';
import LighthouseUtilities from '../../../utils/lighthouse';

const LighthouseGauge = (props) => {
	const {name, score} = props;
	const scoreRounded = Math.round(score);
	const fillRotation = Math.floor((scoreRounded / 100) * 180);
	return <div className="lh-score__gauge">
		<span className="lh-gauge__wrapper">
			<div className={`lh-gauge lh-gauge--${LighthouseUtilities.calculateRating(scoreRounded)}`}
				 data-progress={scoreRounded}>
				<div className="lh-gauge__circle">
					<div className="lh-gauge__mask lh-gauge__mask--full"
						 style={{transform: `rotate(${fillRotation}deg)`}}>
						<div className="lh-gauge__fill" style={{transform: `rotate(${fillRotation}deg)`}}/>
					</div>
					<div className="lh-gauge__mask lh-gauge__mask--half">
						<div className="lh-gauge__fill"
							 style={{transform: `rotate(${fillRotation}deg)`}}/>
						<div className="lh-gauge__fill lh-gauge__fill--fix"
							 style={{transform: `rotate(${fillRotation * 2}deg)`}}/>
					</div>
				</div>
				<div className="lh-gauge__percentage">{scoreRounded}</div>
			</div>
			<div className="lh-gauge__label">{name}</div>
		</span>
	</div>
};

LighthouseGauge.propTypes = {
	score: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
};

export default LighthouseGauge;
