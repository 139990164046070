import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Icon from '../../common/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {makeStyles} from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Drawer from '@material-ui/core/Drawer';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3),
	},
}));


const SidebarContainer = (props) => {
	const {isMenuOpen} = props;
	const {domainId, report, siteId} = props.match.params;
	const classes = useStyles();
	return <Drawer
		open={isMenuOpen}
		className={classes.drawer}
		variant="persistent"
		classes={{paper: classes.drawerPaper,}}>
		<div className={classes.toolbar}/>
		<MenuList>
			<MenuItem selected={report === 'dashboard'}>
				<ListItemIcon>
					<Icon icon="tachometer"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/dashboard`}>
					Dashboard
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'lighthouse-accessibility'}>
				<ListItemIcon>
					<Icon icon="universal-access"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/lighthouse-accessibility`}>
					Accessibility
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'accessibility'}>
				<ListItemIcon>
					<Icon icon="universal-access"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/accessibility`}>
					Advanced Accessibility
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'best-practices'}>
				<ListItemIcon>
					<Icon icon="check-circle"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/best-practices`}>
					Best Practices
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'broken-links'}>
				<ListItemIcon>
					<Icon icon="unlink"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/broken-links`}>
					Broken Links
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'pwa'}>
				<ListItemIcon>
					<Icon icon="file-code"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/pwa`}>
					Progressive Web App
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'seo'}>
				<ListItemIcon>
					<Icon icon="browser"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/seo`}>
					SEO
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'performance'}>
				<ListItemIcon>
					<Icon icon="tachometer-alt"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/performance`}>
					Performance
				</Link>
			</MenuItem>
			<MenuItem selected={report === 'content'}>
				<ListItemIcon>
					<Icon icon="archive"/>
				</ListItemIcon>
				<Link component={RouterLink} to={`/reports/${siteId}/${domainId}/content`}>
					Content
				</Link>
			</MenuItem>
			{/*<MenuItem selected={report === 'security'}>*/}
			{/*	<Link to={`/reports/${siteId}/${domainId}/security`}>*/}
			{/*		<Icon icon="shield"/> Security*/}
			{/*	</Link>*/}
			{/*</MenuItem>*/}
		</MenuList>
	</Drawer>
};

export default SidebarContainer;
