import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
// import './main.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
	<BrowserRouter basename={process.env.NODE_ENV === 'production' ? '/' : ''}>
		<App/>
	</BrowserRouter>,
	document.getElementById('root')
);

// registerServiceWorker();
