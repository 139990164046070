import React, {useState} from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import {Typography} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const UrlSearch = (props) => {
	const {urls, open, cancel} = props;
	const [filter, setFilter] = useState('');
	let filtered = [];

	const onFilter = (e) => setFilter(e.target.value);
	const onSelectUrl = (url) => {
		props.onToggleSearch();
		props.onSelectUrl(url);
	};

	if (urls !== null) {
		if (filter.length) {
			filtered = urls
				.filter(url => url !== undefined)
				.filter(url => url.url.toLowerCase().includes(filter.toLowerCase()))
				.filter((url, index) => index < 100);
		} else {
			filtered = urls.filter((url, index) => index < 100);
		}
	}

	return <Dialog onClose={cancel} scroll='paper' open={open} fullScreen>
		<DialogTitle>
			<TextField autoFocus
					   margin="dense"
					   type="email"
					   onChange={onFilter} placeholder="filter..." value={filter}
					   fullWidth/>
			<Typography>
				<small>pages: {urls.length} | found: {filtered.length}</small>
			</Typography>
		</DialogTitle>
		<DialogContent dividers={true}>
			<List>
				{filtered.map(url => {
					if (url === undefined) {
						return null;
					}
					return <ListItem button onClick={() => onSelectUrl(url)} key={url.name}>
						<ListItemText primary={url.url}/>
					</ListItem>
				})}
			</List>
		</DialogContent>
		<DialogActions>
			<Button onClick={cancel} color="primary">
				Cancel
			</Button>
		</DialogActions>
	</Dialog>
};

UrlSearch.propTypes = {
	open: PropTypes.bool.isRequired,
	urls: PropTypes.array.isRequired,
	onSelectUrl: PropTypes.func.isRequired,
	onToggleSearch: PropTypes.func.isRequired,
};

export default UrlSearch;
