import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import React from 'react';


const ContentAudit = (props) => {
	const {classes, reportDetails} = props;

	return <Table className={classes.table}>
		<TableHead>
			<TableRow>
				<TableCell>Property</TableCell>
				<TableCell>Value</TableCell>
			</TableRow>
		</TableHead>
		<TableBody>
			{Object.keys(reportDetails).map(key => {
				return <TableRow key={key}>
					<TableCell component="th" scope="row">
						{key}
					</TableCell>
					<TableCell>
						{(() => {
							if (!Array.isArray(reportDetails[key])) {
								return reportDetails[key];
							}
							if (key === 'links') {
								let i = 0;
								return <ul>
									{reportDetails[key].map(value => <li key={i++}>{value.href}</li>)}
								</ul>
							}
							if (Array.isArray(reportDetails[key])) {
								return `Found array of values: ${reportDetails[key].length}`;
							}
						})()}
					</TableCell>
				</TableRow>
			})}
		</TableBody>
	</Table>
};

export default ContentAudit;
