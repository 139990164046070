import React, {useEffect, useContext} from 'react';
import {Redirect} from 'react-router-dom';
import {MainContext} from '../../context/main';
import {setAxiosAuthorizationToken} from '../../utils/authorization';

const LogoutPage = () => {
	const {setAuthenticated} = useContext(MainContext);
	useEffect(() => {
		setAuthenticated(null);
		setAxiosAuthorizationToken(null);
		localStorage.removeItem('token');
	}, []);
	return <Redirect to='login'/>;
};

export default LogoutPage;
