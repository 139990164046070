import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LighthouseUtilities from '../../../utils/lighthouse';
import LighthouseAudit51 from './LighthouseAudit51';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(3),
	},
	score: {
		borderBottom: `3px solid ${theme.palette.primary.main}`,
		textAlign: 'center'
	}
}));

const LighthouseCategory51 = (props) => {
	const {category, /*url, */selectedAuditId, onToggleAudit} = props;
	const classes = useStyles();

	const auditsGroupedByGroup = {
		passed: [],
		failed: [],
		notApplicable: [],
	};
	const auditsUngrouped = {passed: [], failed: [], notApplicable: []};

	category.audits.forEach(audit => {
		if (!audit.score) {
			auditsUngrouped.notApplicable.push(audit);
		} else if (audit.score === 1) {
			auditsUngrouped.passed.push(audit);
		} else {
			auditsUngrouped.failed.push(audit);
		}
	});

	const failedCount = auditsGroupedByGroup.failed.length + auditsUngrouped.failed.length;
	const failedElement = failedCount > 0 ? <div>
		<h3>{`${failedCount} Failed Audits`}</h3>
		{auditsUngrouped.failed.map((item, index) => (
			<LighthouseAudit51
				onToggleAudit={onToggleAudit}
				selected={item.id === selectedAuditId}
				key={`audit-ungrouped-${index}`}
				audit={item}/>
		))}
	</div> : null;

	const passedCount = auditsGroupedByGroup.passed.length + auditsUngrouped.passed.length;
	const passedElement = passedCount > 0 ? <div>
		<h3>{`${passedCount} Passed Audits`}</h3>
		{auditsUngrouped.passed.map((item, index) => (
			<LighthouseAudit51
				onToggleAudit={onToggleAudit}
				selected={item.id === selectedAuditId}
				key={`audit-ungrouped-${index}`}
				audit={item}
			/>
		))}
	</div> : null;

	const naAuditCount = auditsGroupedByGroup.notApplicable.length + auditsGroupedByGroup.notApplicable.length;
	const naElement = naAuditCount > 0 ? <div>
		<h3>{`${naAuditCount} Not Applicable Audits`}</h3>
		{auditsUngrouped.notApplicable.map((item, index) => (
			<LighthouseAudit51 onToggleAudit={onToggleAudit}
							 key={`audit-na-ungrouped-${index}`}
							 audit={item}
							 selected={true}/>
		))}
		{auditsGroupedByGroup.notApplicable.map((item, index) => (
			<LighthouseAudit51 onToggleAudit={onToggleAudit}
							 key={`audit-na-grouped-${index}`}
							 audit={item}
							 selected={true}/>
		))}
	</div> : null;

	return <Grid container className={classes.root}>
		<Grid item xs={12}>
			<Typography variant='h5' component='h5' style={{textAlign: 'center'}}>
				This page recieved a score of: {(category.score * 100).toFixed()}%
			</Typography>
		</Grid>
		<Grid item xs={12}>
			<Typography component='p'>
				{LighthouseUtilities.convertMarkdownLinkSnippets(category.description ? category.description : '')}
			</Typography>
		</Grid>
		<Grid item xs={12}>
			{failedElement}
			{passedElement}
			{naElement}
		</Grid>
	</Grid>
};

LighthouseCategory51.propTypes = {
	selectedAuditId: PropTypes.string.isRequired,
	category: PropTypes.shape({
		title: PropTypes.string.isRequired,
		description: PropTypes.string,
		audits: PropTypes.array.isRequired,
	}).isRequired,
	url: PropTypes.shape({
		url: PropTypes.string.isRequired,
		name: PropTypes.string.isRequired,
	}),
	onToggleAudit: PropTypes.func.isRequired,
};

export default LighthouseCategory51;
