import LighthouseReportBase from './LighthouseReportBase';
import React from 'react';

const LighthouseReportSEO = (props) => {
	return <LighthouseReportBase
		{...props}
		reportFriendlyName={'SEO'}
		reportName={'_summary_seo'}
		reportCategory={'SEO'}
		reportIcon={'browser'}/>;
};

export default LighthouseReportSEO;
