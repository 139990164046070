import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Spinner from 'react-spinkit';
import {FadeIn} from 'animate-components';
import ReportTabs from '../../controls/Tabs/ReportTabs';
import ReportWrap from '../../controls/ReportWrap/ReportWrap';
import Icon from '../../../common/Icon';
import {getApiUrl} from '../../../utils/config';
import UrlSearch from '../../controls/UrlSearch/UrlSearch';

// es-lint-ignore jsx-a11y/click-events-have-key-events
class SecurityReport extends React.Component {
	state = {
		isLoaded: false,
		error: false,
		activeTab: 1,
		detailLoading: false,
		selectedUrl: null,
		searching: false,
		reportSummary: null,
		urls: []
	};
	// Store this in a class property so we don't update state when things are clicked
	selectedAuditId = '';
	reportsUrl = '/wp-json/surveyor/v2/reports';

	componentDidMount() {
		const {isLoaded} = this.state;
		const {domain} = this.props;

		if (!isLoaded) {
			axios.get(getApiUrl(`${this.reportsUrl}?domain=${domain}&file=/reports/security_summary.json`))
				.then(response => {
					this.setState({
						reportSummary: response.data,
						isLoaded: true,
						error: false,
					});
				})
				.catch(error => {
					this.setState({
						error,
						isLoaded: true,
					});
				});
		}
	}

	onTabSelect = (tab, e) => {
		e.preventDefault();
		this.setState({activeTab: tab});
	};

	onSelectUrl = (url) => {
		const {domain} = this.props;
		const {name} = url;

		this.setState({
			detailLoading: true,
			activeTab: 2,
		});

		axios.get(getApiUrl(`${this.reportsUrl}?domain=${domain}&file=/header/${name}.json`))
			.then(response => {
				this.setState({
					reportDetails: response.data,
					detailLoading: false,
					selectedUrl: url,
					error: false,
				});
			});
	};

	onToggleAudit = (audit) => {
		this.selectedAuditId = this.selectedAuditId === '' ? audit : '';
	};

	onToggleSearch = () => {
		this.setState({searching: !this.state.searching});
	};

	onAnyClick = () => {
		if (this.state.searching) {
			this.setState({searching: false});
		}
	};

	createDetailReport = (report) => {
		const {reportDetails} = this.state;
		const failedElement = reportDetails.filter(item => !item.working).map((issue, key) => (
			<FadeIn fillMode="both" style={{opacity: 0}} delay={`${0.5 + key / 10}s`}>
				<div className="panel panel-danger">
					<div className="panel-heading">{issue.title}</div>
					<div className="panel-body">
						<strong className="text-capitalize">error:</strong>
						Broken {issue.type === 'a' ? 'Link' : 'Image'}
						<br/>
						<br/>
						<pre><code>{issue.url}</code></pre>
					</div>
					<div className="panel-footer text-sm">
						<h4>
							<small>{issue.selector}</small>
						</h4>
					</div>
				</div>
			</FadeIn>
		));

		return report ? <FadeIn key="fade-in-a11y" timingFunction="ease-in">
			<ReportWrap scanned={0} name="Broken Links" description="" title="" auditClass="lh-failed-audits">
				<div className="grid grid-y">
					<div className="row">
						<h4>
							<a href={this.state.selectedUrl.url}>
								<Icon icon="external-link-alt"/> {this.state.selectedUrl.url}
							</a>
						</h4>
						<hr/>
					</div>
					<div className="row">{failedElement}</div>
				</div>
			</ReportWrap>
		</FadeIn> : <React.Component/>;
	};

	createReport = (report) => {
		const {urls} = this.state;
		let {scanned, name, description, contentSecurityPolicyMissing} = report;
		contentSecurityPolicyMissing = {
			issues: contentSecurityPolicyMissing,
			total: 0
		};
		const failedElements = [];/*Object.keys(contentSecurityPolicyMissing.issues)
			.map(key => contentSecurityPolicyMissing.issues[key])
			.filter(audit => audit !== undefined)
			.sort((a, b) => {
				if(!a.reportedOn || !b.reportedOn) {
					return 0;
				}
				if (a.reportedOn.length < b.reportedOn.length) {
					return 1;
				} else if (a.reportedOn.length > b.reportedOn.length) {
					return -1;
				}
				return 0;
			})
			.map((a, index) => {
				return <SecurityAudit
					auditId={a.key}
					key={`${a.key}-${index}`}
					title={a.title}
					reportedOn={a.reportedOn}
					urlsByName={urlsByName}
					onSelectUrl={this.onSelectUrl}
					onToggleAudit={this.onToggleAudit}
					selected={this.selectedAuditId === a.key}
					brokenUrl={a.url}
					selectors={a.selectors}
				/>
			});*/

		return (
			<FadeIn key={`fade-in-a11y`} timingFunction="ease-in">
				<ReportWrap
					scanned={scanned}
					name={`${name} Summary`}
					description={description}
					title={`${contentSecurityPolicyMissing.total} total issues found on ${urls.length} pages. Common issues below.`}
					auditClass="lh-failed-audits">
					{failedElements}
				</ReportWrap>
			</FadeIn>
		);
	};

	render() {
		let reportElement;
		const {isLoaded, urls, error, activeTab, reportSummary, detailLoading, reportDetails, searching} = this.state;
		if (isLoaded) {
			if (error) {
				const {message} = error;
				reportElement = <div>{message}</div>;
			} else {
				switch (activeTab) {
					case 1:
						reportElement = this.createReport(reportSummary);
						break;
					case 2:
						reportElement = detailLoading ? (
							<div className="large-loader center">
								<Spinner name="cube-grid"/>
							</div>
						) : this.createDetailReport(reportDetails);
						break;
					default:
						reportElement = <div/>;
						break;
				}
			}
		}

		return (
			<div className="content grid-container">
				<div className="grid-x grid-margin-x page-bar">
					<div className="small-12 cell">
						<div>
							<ReportTabs
								activeTab={activeTab}
								searching={searching}
								showDetailsTab={reportDetails !== undefined}
								onTabSelect={this.onTabSelect}
								onToggleSearch={this.onToggleSearch}/>
							<div className="tabs-content height">
								<div className="tabs-panel" id="panel1">
									<div className="responsive-embed"/>
								</div>
								<div className="tabs-panel is-active" id="panel2">
									<div className="lh-root lh-vars">
										<div className="lh-container">
											<div className="lh-report">
												<UrlSearch
													onToggleSearch={this.onToggleSearch}
													open={searching}
													urls={urls ? urls : []}
													onSelectUrl={this.onSelectUrl}
													reportName="security"/>
												<div onClick={this.onAnyClick}>
													{reportElement}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

SecurityReport.propTypes = {
	site: PropTypes.string.isRequired,
	domain: PropTypes.string.isRequired,
	urls: PropTypes.array.isRequired,
	urlsByName: PropTypes.object.isRequired,
};

export default SecurityReport;
