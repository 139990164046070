import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import A11yAudit from './A11yAudit';
import ReportWrap from '../../controls/ReportWrap/ReportWrap';
import {getApiUrl} from '../../../utils/config';
import UrlDetail from '../../controls/UrlDetail/UrlDetail';
import {MainContext} from '../../../context/main';
import UrlSearch from '../../controls/UrlSearch/UrlSearch';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ReportNaErrorMessage from '../../controls/ReportNaErrorMessage';
import {Grid} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LoadingMessage from '../../controls/LoadingMessage';
import SearchIcon from '@material-ui/icons/Search';
import LighthouseAuditSummary from '../Lighthouse/LighthouseAuditSummary';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import LinkIcon from '@material-ui/icons/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(1),
	},
	title: {
		textAlign: 'right',
		paddingTop: theme.spacing(2)
	},
	linkButton: {
		height: '100%',
		marginLeft: '10px'
	},
	searchButton: {
		height: '100%',
	},
	urlField: {
		width: '100%',
		margin: 0
	},
	dialogPaper: {
		minHeight: '80vh',
		maxHeight: '80vh',
	},
	toggleButtons: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}));

const A11yReport = (props) => {

	const [tab, setTab] = useState(1);
	const [isLoaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [reportSummary, setReportSummary] = useState(null);
	const [detailLoading, setDetailLoading] = useState(false);
	const [reportDetails, setReportDetails] = useState(null);
	const [selectedUrl, setSelectedUrl] = useState(null);
	const [selectedUrlInfo, setSelectedUrlInfo] = useState(null);
	const [searching, setSearching] = useState(false);
	const [selectedAuditId, setSelectedAuditId] = useState('');
	const classes = useStyles();

	const {domainId} = props.match.params;
	const {reportsUrl, urls, getUrls, isAuthenticated} = useContext(MainContext);

	useEffect(() => {

		document.title = `A11y - ${domainId}`;

		if (!reportSummary && isAuthenticated) {

			window.dataLayer.push(
				{
					'event': 'viewReport',
					'report' : 'A11y',
					'client': domainId
				}
			);

			axios.get(getApiUrl(`${reportsUrl}?domain=${domainId}&file=/a11y/_summary.json`))
				.then(r1 => {
					setReportSummary(r1.data);
					setLoaded(true);
					setError(false);
				})
				.catch(error => {
					setError(error);
					setLoaded(true);
				});
		}
	}, [isAuthenticated]);

	const toggleError = () => setTab(1);
	const toggleWarning = () => setTab(2);
	const toggleNotice = () => setTab(3);

	useEffect(() => {
		if (isAuthenticated) {
			getUrls(domainId);
		}
	}, [isAuthenticated]);

	const onSelectUrl = (url, auditKey, index, total) => {
		const {name} = url;
		setDetailLoading(true);
		setActiveTab(1);
		axios.get(getApiUrl(`${reportsUrl}?domain=${domainId}&file=/a11y/${name}.json`))
			.then(response => {
				setReportDetails(response.data);
				setDetailLoading(false);
				setSelectedUrl(url);
				setSelectedUrlInfo(auditKey ? {auditKey: auditKey, index: index, total: total} : null);
			})
			.catch(error => {
				setError(error);
				setLoaded(true);
				setSelectedUrlInfo(null);
			});
	};

	const onToggleAudit = (audit) => {
		setSelectedAuditId(selectedAuditId === '' ? audit : '');
	};

	const onToggleSearch = () => {
		setSearching(!searching);
	};

	const onAnyClick = () => {
		if (searching) {
			setSearching(false);
		}
	};

	const createDetailReport = () => {
		if (!reportDetails) {
			return null;
		}
		const {issues} = reportDetails;
		const failedElement = <UrlDetail tab={tab} issues={issues} url={selectedUrl}/>;
		const scanned = 0;
		return <ReportWrap scanned={scanned}>
			{failedElement}
		</ReportWrap>
	};

	const createReport = () => {
		if (!reportSummary) {
			return null;
		}
		const failedElements = Object.keys(reportSummary.errors)
			.map(key => reportSummary.errors[key])
			.filter(audit => audit !== undefined && audit.reportedOn.length)
			.sort((a, b) => {
				if (a.reportedOn.length < b.reportedOn.length) {
					return 1;
				} else if (a.reportedOn.length > b.reportedOn.length) {
					return -1;
				}
				return 0;
			})
			.map((a, index) => {

				const urlsNew = a.reportedOn
					.map(file => {
						const us = urls.filter(url => url.name === file.replace('.json', ''));
						if (us.length > 0) {
							return us[0];
						}
						return null;
					})
					.filter(url => url !== null)
					.sort((a, b) => a.url.localeCompare(b.url));

				return <A11yAudit
					key={`${a.code}-${index}`}
					auditId={a.code}
					rule={a.rule}
					title={a.code}
					helpText={a.rule.description}
					scoringMode="binary"
					count={a.reportedOn.length}
					urls={urlsNew ? urlsNew : []}
					onSelectUrl={onSelectUrl}
					onToggleAudit={onToggleAudit}
					selected={selectedAuditId === a.rule.id}/>
			});

		return <LighthouseAuditSummary
			scanned={reportSummary.scanned}
			score={reportSummary.score}
			name={`${reportSummary.name} Summary`}
			description={reportSummary.description}
			title={`${failedElements.length} Unique Errors on ${urls ? urls.length : 0} Pages`}>
			{failedElements}
		</LighthouseAuditSummary>
	};

	if (error) {
		return <ReportNaErrorMessage/>;
	}

	const handleClose = () => {
		setActiveTab(0);
	};

	const prevUrl = () => {
		if (reportSummary && selectedUrlInfo) {
			const url = Object.keys(reportSummary.errors)
				.map(key => reportSummary.errors[key])
				.filter(audit => audit.code === selectedUrlInfo.auditKey)
				.map(a => {
					const urlsNew = a.reportedOn
						.map(file => {
							const us = urls.filter(url => url.name === file.replace('.json', ''));
							if (us.length > 0) {
								return us[0];
							}
							return null;
						})
						.filter(url => url !== null)
						.sort((a, b) => a.url.localeCompare(b.url));
					if (urlsNew.length > (selectedUrlInfo.index - 1) && (selectedUrlInfo.index - 1) > -1) {
						return urlsNew[selectedUrlInfo.index - 1];
					}
					return null;
				});
			if (url.length > 0 && url[0] !== null) {
				onSelectUrl(url[0], selectedUrlInfo.auditKey, selectedUrlInfo.index - 1, selectedUrlInfo.total)
			}
		}
	};

	const nextUrl = () => {
		if (reportSummary && selectedUrlInfo) {
			const url = Object.keys(reportSummary.errors)
				.map(key => reportSummary.errors[key])
				.filter(audit => audit.code === selectedUrlInfo.auditKey)
				.map((a) => {
					const urlsNew = a.reportedOn
						.map(file => {
							const us = urls.filter(url => url.name === file.replace('.json', ''));
							if (us.length > 0) {
								return us[0];
							}
							return null;
						})
						.filter(url => url !== null)
						.sort((a, b) => a.url.localeCompare(b.url));
					if (urlsNew.length > (selectedUrlInfo.index + 1)) {
						return urlsNew[selectedUrlInfo.index + 1];
					}
					return null;
				});
			if (url.length > 0 && url[0] !== null) {
				onSelectUrl(url[0], selectedUrlInfo.auditKey, selectedUrlInfo.index + 1, selectedUrlInfo.total)
			}
		}
	};

	return isLoaded ? <div className={classes.root}>
		{createReport()}
		<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={activeTab === 1} maxWidth='lg'
				fullWidth={true}
				classes={{paper: classes.dialogPaper}}
				scroll='paper'>
			<DialogTitle id="simple-dialog-title">
				<Grid container>
					<Grid item xs={1}>
						<Button color="primary" aria-label="Find a Page" className={classes.searchButton}
								onClick={onToggleSearch}>
							<SearchIcon/>
						</Button>
						<UrlSearch
							onToggleSearch={onToggleSearch}
							cancel={onAnyClick}
							open={searching}
							urls={urls ? urls : []}
							onSelectUrl={onSelectUrl}/>
					</Grid>
					<Grid item xs={8}>

						<Tooltip title={selectedUrl ? selectedUrl.name : 'no page selected'} interactive>
							<TextField
								value={selectedUrl ? selectedUrl.url : 'no page selected'}
								disabled={true}
								className={classes.urlField}
								margin="normal"
								variant="outlined"/>
						</Tooltip>
					</Grid>
					<Grid item xs={1}>
						{selectedUrl ?
							<Button href={selectedUrl.url} target="_blank" rel="noopener noreferer"
									className={classes.linkButton}>
								<LinkIcon/>
							</Button> : null}
					</Grid>
					<Grid item xs={2}>
						<div className={classes.title}>
							Accessibility Issues
						</div>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent dividers={true}>
				{detailLoading ? <LoadingMessage/> : createDetailReport()}
			</DialogContent>
			<DialogActions>
				<Grid container>
					<Grid item xs={4}>
						<Button aria-label="Previous page" onClick={prevUrl}>
							<ArrowBackIcon/>
						</Button>
						<Button aria-label="Next page" onClick={nextUrl}>
							<ArrowForwardIcon/>
						</Button>
					</Grid>
					<Grid item xs={4} style={{textAlign: 'center'}}>
						<Button onClick={toggleError} color='primary'
								className={classes.toggleButtons}
								variant={`${tab !== 1 ? 'text' : 'contained'}`}>
							Errors
						</Button>
						<Button onClick={toggleWarning} color='primary'
								className={classes.toggleButtons}
								variant={`${tab !== 2 ? 'text' : 'contained'}`}>
							Warnings
						</Button>
						<Button onClick={toggleNotice} color='primary'
								className={classes.toggleButtons}
								variant={`${tab !== 3 ? 'text' : 'contained'}`}>
							Notices
						</Button>
					</Grid>
					<Grid item xs={4} style={{textAlign: 'right'}}>
						<Button onClick={handleClose} color="primary">
							Close
						</Button>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	</div> : <LoadingMessage/>
};

export default A11yReport;
