import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import LighthouseUtilities from '../../../utils/lighthouse';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {Grid} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
}));

const LighthouseAudit51 = (props) => {
	const {audit} = props;
	const classes = useStyles();

	let {title, description} = audit;
	return <ExpansionPanel>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content">
			<Typography className={classes.heading}>
				{LighthouseUtilities.convertMarkdownCodeSnippets(title)}
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography>
						{LighthouseUtilities.convertMarkdownLinkSnippets(description)}
					</Typography>
				</Grid>
			</Grid>
		</ExpansionPanelDetails>
	</ExpansionPanel>
};

LighthouseAudit51.propTypes = {
	selected: PropTypes.bool.isRequired,
	audit: PropTypes.shape({
		id: PropTypes.string.isRequired,
		result: PropTypes.shape({
			scoringMode: PropTypes.string.isRequired,
			helpText: PropTypes.string.isRequired,
		}),
	}).isRequired,
	onToggleAudit: PropTypes.func.isRequired,
};

export default LighthouseAudit51;
