import React, {useContext} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import {MainContext} from '../../context/main';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import {MenuList} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(6),
	},
	paper: {
		height: 140,
		width: 100,
	},
	domainMenu: {
		flexGrow: 1,
	}
}));

const Home = () => {
	const {sites} = useContext(MainContext);
	const classes = useStyles();

	document.title = 'Ellucian Surveyor';

	return <Grid container spacing={2} className={classes.root}>
		{sites && sites.map(site => {
			const {title, slug, domains, id} = site;
			const {rendered} = title;
			return <Grid item key={id} xs={12} md={6}>
				<Card>
					<CardHeader
						title={<Link component={RouterLink}
									 to={`/reports/${slug}/${domains[0]}/dashboard`}>{rendered}</Link>}
						subheader={`This site has ${domains.length} domain${domains.length === 1 ? '' : 's'}`}/>
					<CardContent>
						<ExpansionPanel>
							<ExpansionPanelSummary
								expandIcon={<ExpandMoreIcon/>}
								aria-controls="panel1a-content"
								id="panel1a-header">
								<Typography className={classes.heading}>
									Domains: {domains.length > 0 ? domains[0] : 'none available'}
								</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<MenuList className={classes.domainMenu}>
									{domains.map((domain, i) => <MenuItem key={i}>
										<Link component={RouterLink}
											  to={`/reports/${slug}/${domain}/dashboard`}>{domain}</Link>
									</MenuItem>)}
								</MenuList>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</CardContent>
				</Card>
			</Grid>
		})}
	</Grid>
};

export default Home;
