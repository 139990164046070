import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LighthouseUtilities from '../../../utils/lighthouse';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(1),
	},
	score: {
		borderBottom: `3px solid ${theme.palette.primary.main}`,
		textAlign: 'center'
	},
	progress: {}
}));

const LighthouseAuditSummary = (props) => {
	const {scanned, score, name, description, title, children} = props;
	const classes = useStyles();
	return <Grid container className={classes.root} spacing={3}>
		<Grid item xs={10}>
			<Typography variant='h4' component='h3'>{name}</Typography>
		</Grid>
		{score ? <Grid item xs={2}>
			<Typography variant='h3' component='h4' className={classes.score}>
				{(score > 100 ? (score / scanned) : score).toFixed()}%
			</Typography>
		</Grid> : null}
		<Grid item xs={12}>
			<Typography component='p'>
				{LighthouseUtilities.convertMarkdownLinkSnippets(description)}
			</Typography>
			<h3>{title}</h3>
			{children}
		</Grid>
	</Grid>
};

LighthouseAuditSummary.propTypes = {
	score: PropTypes.number.isRequired,
	scanned: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LighthouseAuditSummary;
