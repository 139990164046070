import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import LighthouseGauge from '../../reports/Lighthouse/LighthouseGauge';
import {Grid} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
	root: {
		paddingTop: theme.spacing(2),
	},
	score: {
		position: 'absolute',
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(0.5),
	},
	progress: {}
}));

const ReportWrap = (props) => {
	const {score, name, description, title, children} = props;
	const classes = useStyles();
	return <Grid container className={classes.root} spacing={2}>
		{name ? <Grid item xs={10}>
			<Typography variant='h4' component='h4'>{name}</Typography>
		</Grid> : null}
		{score && name ? <Grid item xs={2}>
			<LighthouseGauge score={score} name={name}/>
		</Grid> : null}
		{description ? <Grid item xs={12}>
			<p>{description}</p>
		</Grid> : null}
		<Grid item xs={12}>
			{title ? <summary>
				<Typography>{title}</Typography>
			</summary> : null}
			{children}
		</Grid>
	</Grid>
};

ReportWrap.defaultProps = {
	score: 0,
};

ReportWrap.propTypes = {
	score: PropTypes.number,
	name: PropTypes.string,
	description: PropTypes.string,
	title: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ReportWrap;
