import React from 'react';
// noinspection ES6CheckImport
import PropTypes from 'prop-types';
import UrlTable from '../../controls/UrlTable/UrlTable';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {Grid} from '@material-ui/core';
import {Code} from '../../controls/Code';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	margin: {
		marginRight: theme.spacing(2),
	},
	code: {
		overflow: 'scroll',
		width: theme.spacing(100)
	}

}));

const BrokenLinkAudit = (props) => {
	const {brokenUrl, title, count, urls, selectors, onSelectUrl, auditId} = props;
	const classes = useStyles();

	const trimText = (text, length) => {
		return text.length > length ? `${text.substr(0, length - 1)}...` : text;
	};

	return <ExpansionPanel>
		<ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content" id="panel1a-header">
			<Chip className={classes.margin} label={count} color="primary"/>
			<Typography className={classes.heading}>
				{trimText(brokenUrl, 135)}
			</Typography>
		</ExpansionPanelSummary>
		<ExpansionPanelDetails>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Code codeText={title}/>
					{selectors.map((selector, index) => {
						return <Code key={index} codeText={selector} color='secondary' enableCopy={true}/>
					})}
				</Grid>
				<Grid item xs={12}>
					<UrlTable onSelectUrl={onSelectUrl} label="Found On" urls={urls} auditId={auditId} count={count}/>
				</Grid>
			</Grid>
		</ExpansionPanelDetails>
	</ExpansionPanel>
};

BrokenLinkAudit.propTypes = {
	selected: PropTypes.bool.isRequired,
	auditId: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	urls: PropTypes.array.isRequired,
	onSelectUrl: PropTypes.func.isRequired,
	onToggleAudit: PropTypes.func.isRequired,
	brokenUrl: PropTypes.string.isRequired,
	selectors: PropTypes.array.isRequired,
};

export default BrokenLinkAudit;
