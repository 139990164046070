import React, {useContext, useState} from 'react';
import {MainContext} from '../../context/main';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Avatar from '@material-ui/core/Avatar';
import {Link as RouterLink, Redirect} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		Avatar: {
			backgroundColor: '#ffffff'
		}
	},
	favicon: {
		marginLeft: 0
	},
	title: {
		flexGrow: 1,
	},
}));

const TopBarContainer = (props) => {
	const {handleMenuOpen} = props;
	const {domainId} = props.match.params;
	const {isAuthenticated, favicon} = useContext(MainContext);
	const [isLogout, setLogout] = useState(false);
	const classes = useStyles();
	if(isLogout) {
		return <Redirect to="/logout" />
	}
	const faviconUrl = favicon ? favicon : null;

	return <div className={classes.root}>
		<AppBar position="fixed" color="primary" className={classes.appBar}>
			<Toolbar>
				{handleMenuOpen ?
					<IconButton color="inherit" aria-label="Open Menu" onClick={handleMenuOpen} edge="start">
						<MenuIcon/>
					</IconButton> : null}
				<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
					<Avatar alt="Ellucian Surveyor" src="/favicon-white.png"/>
				</IconButton>
				<Typography variant="h6" component={RouterLink} color="inherit" to="/" className={classes.title}>
					Surveyor
				</Typography>
				{domainId ? <Typography variant="h6" color="inherit" className={classes.title}>
					{faviconUrl ? <IconButton edge="start" className={classes.favicon} color="inherit" aria-label="Menu">
						<Avatar alt={domainId} src={faviconUrl}/>
					</IconButton> : null}
					{domainId}
				</Typography> : null}
				{isAuthenticated ? <Button onClick={() => setLogout(true)} color="inherit">Logout</Button> : null}
			</Toolbar>
		</AppBar>
	</div>
};

export default TopBarContainer;
